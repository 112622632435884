module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"bloglisting":"/home/runner/work/serverless-cms-azure/serverless-cms-azure/src/templates/bloglisting.tsx","blogpost":"/home/runner/work/serverless-cms-azure/serverless-cms-azure/src/templates/blogpost.tsx","page":"/home/runner/work/serverless-cms-azure/serverless-cms-azure/src/templates/page.tsx","default":"/home/runner/work/serverless-cms-azure/serverless-cms-azure/src/templates/page.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-plugin-netlify-cms-paths","options":{"cmsConfig":"/static/admin/config.yml"}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1rem"}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants",{"resolve":"gatsby-remark-images","options":{"maxWidth":1140,"quality":100,"backgroundColor":"transparent","linkImagesToOriginal":false,"withWebp":true,"showCaptions":["title"]}}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/serverless-cms-azure/serverless-cms-azure"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://serverlesscms.danielbass.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
